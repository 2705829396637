import React, { useState, useEffect } from "react";
import "./ProfilePage.css";
import "./AnimeBlock.css";
import logger from "./Logger";
import { useNavigate } from "react-router-dom";
import "./HistoryPage.css";
import Loader from "./loader";

const HistoryPage = () => {
    const [setHistoryData] = useState([]);
    const [groupedHistory, setGroupedHistory] = useState({});
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const fetchHistory = async () => {
            const token = localStorage.getItem("authToken");
            if (!token) {
                navigate("/login");
                return;
            }

            try {
                const historyResponse = await fetch(`${API_BASE_URL}/history`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!historyResponse.ok) {
                    throw new Error("Ошибка при загрузке истории");
                }

                const historyItems = await historyResponse.json();

                const historyWithDetails = await Promise.all(
                    historyItems.map(async (item) => {
                        const animeResponse = await fetch(`${API_BASE_URL}/anime/${item.animeId}`);
                        if (!animeResponse.ok) {
                            throw new Error("Ошибка при загрузке данных аниме");
                        }
                        const animeData = await animeResponse.json();
                        return {
                            ...item,
                            posterUrl: animeData.material_data.anime_poster_url,
                        };
                    })
                );

                const grouped = historyWithDetails.reduce((acc, item) => {
                    const date = new Date(item.lastWatched).toLocaleDateString("ru-RU");
                    if (!acc[date]) {
                        acc[date] = [];
                    }
                    acc[date].push(item);
                    return acc;
                }, {});

                setGroupedHistory(grouped);
                setHistoryData(historyWithDetails);
            } catch (error) {
                logger.error(`Ошибка при загрузке истории: ${error}`, "HistoryPage - fetchHistory");
            } finally {
                setLoading(false);
            }
        };

        fetchHistory();
    }, [navigate, API_BASE_URL, setHistoryData]);

    const handleAnimeClick = (animeId, episodeNumber, studioId) => {
        navigate(`/video/${animeId}`, { state: { episode: episodeNumber, studioId } });
    };

    if (loading) {
        return (
            <div className="profile-page">
                <Loader />
            </div>
        );
    }

    return (
        <div className="history-page">
            <div className="history-container">
                {Object.entries(groupedHistory)
                    .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA))
                    .map(([date, items]) => (
                        <div key={date} className="history-group">
                            <h2 className="date-header">{date}</h2>
                            <div className="anime-grid">
                                {items.map((item) => (
                                    <div
                                        key={`${item.animeId}-${item.lastWatched}`}
                                        className="anime-card"
                                        onClick={() => handleAnimeClick(item.animeId, item.episodeNumber, item.studioId)}
                                    >
                                        <div className="image-container">
                                            <img
                                                src={item.posterUrl}
                                                alt={item.title}
                                                style={{ width: "128px", height: "182px" }}
                                            />
                                        </div>
                                        <p style={{ color: "white", maxWidth: "128px" }}>{item.title}</p>
                                        <span style={{ color: "#b3b3b3", maxWidth: "128px" }}>
                                            Серия {item.episodeNumber}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default HistoryPage;
