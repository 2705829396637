import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./ProfilePage.css";
import AnimeBlock from './AnimeBlock';
import logger from "./Logger";
import Loader from "./loader";
import Translate from "./Translate";

const ProfilePage = ({ user, setUser }) => {
    const [library, setLibrary] = useState({
        watchlist: [],
        favorites: [],
        dropped: [],
        watched: [],
    });
    const [loading, setLoading] = useState(true);
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'ru');
    const navigate = useNavigate();

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const handleLogout = useCallback(() => {
        localStorage.removeItem("authToken");
        setUser(null);
        navigate("/login");
    }, [navigate, setUser]);

    const handleAnimeClick = (animeId) => {
        navigate(`/info/${animeId}`);
    };

    const handleLanguageChange = (event) => {
        const newLanguage = event.target.value;
        setLanguage(newLanguage);
        localStorage.setItem('language', newLanguage);
    };

    const fetchAnimeDetails = useCallback(async (animeId) => {
        try {
            const response = await fetch(`${API_BASE_URL}/anime/${animeId}`);
            if (!response.ok) {
                throw new Error("Ошибка при загрузке данных аниме.");
            }
            const anime = await response.json();

            return {
                id: anime.id,
                title: anime.title,
                poster: anime.material_data.anime_poster_url,
                info: `${anime.material_data.episodes_total} серий`,
                rating: anime.material_data.shikimori_rating,
                description: `${anime.year}, ${anime.material_data.anime_genres?.join(', ')}`,
            };
        } catch (error) {
            logger.error(`Ошибка при загрузке данных аниме: ${error}`, "ProfilePage - fetchAnimeDetails");
            return {
                id: animeId,
                title: "Неизвестно",
                poster: "",
                info: "Данные недоступны",
            };
        }
    }, [API_BASE_URL]);

    useEffect(() => {
        const fetchLibraryWithDetails = async () => {
            try {
                const token = localStorage.getItem("authToken");
                if (!token) {
                    setLoading(false);
                    return;
                }

                const response = await fetch(`${API_BASE_URL}/library`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    if (response.status === 401) {
                        logger.warn('Неавторизован (401). Принудительный логаут.', 'ProfilePage - fetchLibraryWithDetails');
                        handleLogout();
                        return;
                    }

                    throw new Error("Ошибка при загрузке библиотеки.");
                }

                const data = await response.json();

                const libraryWithDetails = {};
                for (const listType in data) {
                    const animeDetails = await Promise.all(
                        data[listType].map((animeId) => fetchAnimeDetails(animeId))
                    );
                    libraryWithDetails[listType] = animeDetails.reverse();
                }

                setLibrary(libraryWithDetails);
            } catch (error) {
                logger.error(`Ошибка при загрузке библиотеки: ${error}`, "ProfilePage - fetchLibraryWithDetails");
            } finally {
                setLoading(false);
            }
        };

        fetchLibraryWithDetails();
    }, [API_BASE_URL, fetchAnimeDetails, handleLogout]);

    if (!user) {
        return (
            <div className="profile-page">
                <h2><Translate textKey="global.error.unauthorized" /></h2>
                <button onClick={() => navigate("/login")}>
                    <Translate textKey="profile.page.button.login" />
                </button>
            </div>
        );
    }

    if (loading) {
        return (
            <div className="profile-page">
                <Loader />
            </div>
        );
    }

    const sectionTitles = {
        watchlist: <Translate textKey="profile.page.watchlist" />,
        favorites: <Translate textKey="profile.page.favorites" />,
        dropped: <Translate textKey="profile.page.dropped" />,
        watched: <Translate textKey="profile.page.watched" />,
    };

    const displayOrder = ["watchlist", "favorites", "dropped", "watched"];

    return (
        <div className="profile-page">
            <div className="profile-header">
                <h1><Translate textKey="profile.header.title" /></h1>
                <select
                    value={language}
                    onChange={handleLanguageChange}
                    className="language-select"
                >
                    <option value="ru">
                        <Translate textKey="profile.page.language.ru" />
                    </option>
                    <option value="en">
                        <Translate textKey="profile.page.language.en" />
                    </option>
                </select>
            </div>

            <div className="library">
                {displayOrder.map((listType) => {
                    const items = library[listType] || [];
                    return (
                        <div key={listType} className="library-section">
                            <AnimeBlock
                                title={sectionTitles[listType]}
                                items={items.map((anime) => ({
                                    image: anime.poster,
                                    title: anime.title,
                                    info: anime.info,
                                    rating: anime.rating,
                                    description: anime.description,
                                    id: anime.id,
                                }))}
                                imageStyle={{ width: '128px', height: '182px' }}
                                textWidth="128px"
                                gap={27}
                                hoverEffect={true}
                                onCardClick={(item) => handleAnimeClick(item.id)}
                                titleStyle={{ color: 'white' }}
                                infoStyle={{ color: 'gray' }}
                            />
                        </div>
                    );
                })}
            </div>

            <button className="logout-button" onClick={handleLogout}>
                <Translate textKey="profile.page.button.logout" />
            </button>
        </div>
    );
};

export default ProfilePage;
