import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logger from '../components/Logger';
import './Login.css';

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: '', password: '' });

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_BASE_URL}/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (data.token) {
        localStorage.setItem('authToken', data.token);
        localStorage.setItem('username', data.username);
        navigate('/');
      } else {
        alert(data.message || 'Ошибка авторизации.');
      }
    } catch (error) {
      logger.error(`Ошибка авторизации: ${error}`, 'Login - handleSubmit');
    }
  };

  return (
    <div className="login-page-container">
      <div className="login-form-wrapper">
        <h1 className="login-title">Вход в аккаунт</h1>
        <form onSubmit={handleSubmit} className="login-form">
          <div className="login-input-group">
            <input
              type="email"
              placeholder="Email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              className="login-input"
            />
          </div>
          <div className="login-input-group">
            <input
              type="password"
              placeholder="Пароль"
              value={formData.password}
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
              className="login-input"
            />
          </div>
          <button type="submit" className="login-submit-btn">
            Войти
          </button>
        </form>
        <div className="login-links">
          <a href="/register" className="login-register-link">
            Нет аккаунта? Зарегистрироваться
          </a>
          <a href="/forgot-password" className="login-forgot-link">
            Забыли пароль?
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login;
