import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Info from './pages/Info';
import Home from './pages/Home';
import Video from './pages/Video';
import Search from './pages/Search';
import Register from './pages/Register';
import Login from './pages/Login';
import Profile from './pages/Profile';
import History from './pages/History';
import logger from './components/Logger';

function App() {
  const [user, setUser] = useState(null);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');

    if (!authToken) {
      setUser(null);
      return;
    }

    const clearAuthData = () => {
      localStorage.removeItem('authToken');
      localStorage.removeItem('username');
      setUser(null);
    };

    const fetchUserData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/users/me`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (!response.ok) {
          if (response.status === 401) {
            logger.warn('Токен недействителен или устарел. Удаление данных авторизации.');
          } else {
            logger.error(`Ошибка запроса: ${response.status} ${response.statusText}`);
          }
          clearAuthData();
          return;
        }

        const data = await response.json();
        setUser(data);
      } catch (error) {
        logger.error(`Ошибка при загрузке данных пользователя: ${error}`, 'App - fetchUserData');
        clearAuthData();
      }
    };

    fetchUserData();
  }, [API_BASE_URL]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/video/:animeId" element={<Video />} />
        <Route path="/info/:animeId" element={<Info />} />
        <Route path="/search" element={<Search />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile" element={<Profile user={user} setUser={setUser} />} />
        <Route path="/history" element={<History user={user} setUser={setUser} />} />
      </Routes>
    </Router>
  );
}

export default App;
