import React, { useState, useEffect, useCallback } from 'react';
import './Slider.css';

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    'https://anilis.fun/image.png',
    'https://anilis.fun/image2.png',
    'https://anilis.fun/image3.png',
  ];

  const nextSlide = useCallback(() => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  }, [slides.length]);

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000);
    return () => clearInterval(interval);
  }, [nextSlide]);

  return (
    <div className="slider">
      <button className="slider-button left" onClick={prevSlide}>
        ◀
      </button>
      <div className="slider-content">
        {slides.map((slide, index) => {
          const position =
            index === currentSlide
              ? 'center'
              : index === (currentSlide - 1 + slides.length) % slides.length
                ? 'left'
                : 'right';

          return (
            <div
              key={index}
              className={`slide ${position}`}
              style={{
                backgroundImage: `url(${slide})`,
              }}
            />
          );
        })}
      </div>
      <button className="slider-button right" onClick={nextSlide}>
        ▶
      </button>
    </div>
  );
};

export default Slider;
