const translationEn = {
    'global.loading.text': 'Loading...',
    'global.error.general': 'An error occurred',
    'global.error.notFound': 'Page not found',
    'global.error.unauthorized': 'You are not authorized',
    'global.buttons.submit': 'Submit',
    'global.buttons.cancel': 'Cancel', 
    'global.buttons.save': 'Save',
    'global.messages.success': 'Operation completed successfully',
    'global.messages.welcome': 'Welcome',

    'global.navigation.home': 'Home',
    'global.navigation.history': 'History',
    'global.navigation.random': 'Random',
    'global.navigation.search': 'Search',

    'profile.header.title': 'Profile',
    'profile.page.language': 'Language',
    'profile.page.language.ru': 'Russian',
    'profile.page.language.en': 'English',
    'profile.page.button.login': 'Login',
    'profile.page.button.logout': 'Logout',
    'profile.page.button.profile': 'Profile',
    'profile.page.watchlist': 'Watchlist',
    'profile.page.favorites': 'Favorites',
    'profile.page.dropped': 'Dropped',
    'profile.page.watched': 'Watched',

    'anime.info.description': 'Description',
    'anime.info.screenshots': 'Screenshots',
    'anime.info.button.add': 'Add to ',
    'anime.info.button.addlist': 'Add to list',
    'anime.info.button.remove': 'Remove from ',
    'anime.info.button.watch': 'Watch',

    'anime.info.button.watchlist': 'watchlist',
    'anime.info.button.favorites': 'favorites',
    'anime.info.button.dropped': 'dropped',
    'anime.info.button.watched': 'watched',

    'anime.info.rating': 'Rating: ',
    'anime.info.season': 'Season: ',
    'anime.info.episodes': 'Episodes: ',
    'anime.info.age': 'Age: ',
    'anime.info.year': 'Year: ',
    'anime.info.genres': 'Genres: ',
    'anime.info.voice': 'Voice: ',
    'anime.info.voice.notfound': 'Voice not found',

    'home.continue.watching': 'Continue watching',
    'home.high.rated': 'With a high rating',
    'home.top.anime.2024': 'Top anime 2024',
    'home.top.anime.2023': 'Top anime 2023',

    // 'anime.info.episodes': 'episodes',
};

export default translationEn;
