import React from 'react';
import Translate from './Translate';

const Loader = () => {
    const loaderStyle = {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '80vh',
    };

    const spinnerStyle = {
        width: '50px',
        height: '50px',
        border: '5px solid #1a1a1a',
        borderTop: '5px solid #ffffff',
        borderRadius: '50%',
        animation: 'spin 1s linear infinite',
    };

    const loaderTextStyle = {
        color: '#fff',
        fontSize: '30px',
        paddingBottom: '20px',
    };

    return (
        <div style={loaderStyle}>
            <style>
                {`
                    @keyframes spin {
                        0% { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                    }
                `}
            </style>
            <div style={loaderStyle}>
                <h2 style={loaderTextStyle}><Translate textKey="global.loading.text" /></h2>
                <div style={spinnerStyle} />
            </div>
        </div>
    );
};

export default Loader;
