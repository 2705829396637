const logger = {
    log: (message, functionName) => {
        if (process.env.REACT_APP_MODE === 'DEV') {
            const time = new Date().toISOString().slice(11, 23);
            console.log(
                `%c${time}%c | %c[${functionName}]%c ${message}`,
                'background: #99b4be; color: black; padding: 2px 6px; border-radius: 3px; font-weight: bold;',
                'background: transparent; color: white;', // Невидимая подложка
                'background: #176723; color: white; padding: 2px 6px; border-radius: 3px; font-weight: bold;',
                'color: white;'
            );
        }
    },

    error: (message, functionName) => {
        if (process.env.REACT_APP_MODE === 'DEV') {
            const time = new Date().toISOString().slice(11, 23);
            console.error(
                `%c${time}%c | %c[ERROR]%c %c[${functionName}]%c ${message}`,
                'background: #99b4be; color: black; padding: 2px 6px; border-radius: 3px; font-weight: bold;',
                'background: transparent; color: white;',
                'background: #ffcdd2; color: #f44336; padding: 2px 6px; border-radius: 3px;',
                'background: transparent; color: white;',
                'background: #176723; color: white; padding: 2px 6px; border-radius: 3px; font-weight: bold;',
                'color: #fff;'
            );
        }
    },

    warn: (message, functionName) => {
        if (process.env.REACT_APP_MODE === 'DEV') {
            const time = new Date().toISOString().slice(11, 23);
            console.warn(
                `%c${time}%c | %c[WARN]%c %c[${functionName}]%c ${message}`,
                'background: #99b4be; color: black; padding: 2px 6px; border-radius: 3px; font-weight: bold;',
                'background: transparent; color: white;',
                'background: #ffe0b2; color: #ff9800; padding: 2px 6px; border-radius: 3px;',
                'background: transparent; color: white;',
                'background: #176723; color: white; padding: 2px 6px; border-radius: 3px; font-weight: bold;',
                'color: #fff;'
            );
        }
    }
};

export default logger;



