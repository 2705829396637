import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Slider from '../components/Slider';
import AnimeBlock from '../components/AnimeBlock';
import './Home.css';
import logger from '../components/Logger';
import Translate from '../components/Translate';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Home = () => {
  const [highRated, setHighRated] = useState([]);
  const [topAnime2023, setTopAnime2023] = useState([]);
  const [highRatedFull, setHighRatedFull] = useState([]);
  const [continueWatching, setContinueWatching] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isPosterLoading, setIsPosterLoading] = useState(true);
  const [isContinueWatchingLoading, setIsContinueWatchingLoading] = useState(false);
  const [showContinueWatching, setShowContinueWatching] = useState(false);
  
  const navigate = useNavigate();

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchData = async () => {
      setIsDataLoading(true);
      setIsPosterLoading(true);

      try {
        const [highRatedFullResponse, highRated2024Response, top2023Response] = await Promise.all([
          fetch(`${API_BASE_URL}/anime/top`),
          fetch(`${API_BASE_URL}/anime/top?year=2024`),
          fetch(`${API_BASE_URL}/anime/top?year=2023`),
        ]);

        if (!highRatedFullResponse.ok || !highRated2024Response.ok || !top2023Response.ok) {
          throw new Error('Ошибка при загрузке данных.');
        }

        const [highRatedFullData, highRated2024Data, top2023Data] = await Promise.all([
          highRatedFullResponse.json(),
          highRated2024Response.json(),
          top2023Response.json(),
        ]);

        setHighRatedFull(highRatedFullData);
        setHighRated(highRated2024Data);
        setTopAnime2023(top2023Data);

        const allAnime = [...highRatedFullData, ...highRated2024Data, ...top2023Data];
        await Promise.all(
          allAnime.map(anime => 
            new Promise((resolve) => {
              const img = new Image();
              img.src = anime.material_data.anime_poster_url;
              img.onload = resolve;
              img.onerror = resolve;
            })
          )
        );

        setIsPosterLoading(false);
      } catch (error) {
        logger.error(`Ошибка загрузки данных: ${error}`, 'Home - fetchData');
      } finally {
        setIsDataLoading(false);
      }
    };

    fetchData();
  }, [API_BASE_URL]);

  useEffect(() => {
    const fetchContinueWatching = async () => {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) return;

      try {
        setIsContinueWatchingLoading(true);

        const historyResponse = await fetch(`${API_BASE_URL}/history`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (!historyResponse.ok) {
          if (historyResponse.status === 401) {
            logger.warn('Неавторизован.', 'Home - fetchContinueWatching');
          } else {
            logger.error(
              `Ошибка загрузки истории: ${historyResponse.statusText}`,
              'Home - fetchContinueWatching'
            );
          }
          return;
        }

        const historyData = await historyResponse.json();
        if (historyData.length > 0) {
          setShowContinueWatching(true);

          const continueWatchingData = await Promise.all(
            historyData.map(async (item) => {
              const animeResponse = await fetch(`${API_BASE_URL}/anime/${item.animeId}`);
              if (!animeResponse.ok) {
                throw new Error('Ошибка при загрузке данных аниме.');
              }
              const animeData = await animeResponse.json();

              const season = animeData.seasons?.[1] || animeData.seasons?.[0];
              const episodeScreenshots = season?.episodes?.[item.lastWatchedEpisode]?.screenshots;

              return {
                image: episodeScreenshots?.[0] || animeData.material_data.anime_poster_url,
                title: item.title,
                info: `Сезон 1, Серия ${item.lastWatchedEpisode}`,
                progress: item.progress,
                animeId: item.animeId,
                episodeNumber: item.lastWatchedEpisode,
                studioId: item.studioId,
              };
            })
          );

          setContinueWatching(continueWatchingData);
        }
      } catch (error) {
        logger.error(`Ошибка загрузки данных для "Продолжить просмотр": ${error}`, 'Home - fetchContinueWatching');
      } finally {
        setIsContinueWatchingLoading(false);
      }
    };

    fetchContinueWatching();
  }, [API_BASE_URL]);

  const handleAnimeClick = (animeId) => {
    navigate(`/info/${animeId}`);
  };

  const handleAnimeClickCont = (animeId, episodeNumber, studioId) => {
    if (!animeId || !episodeNumber) {
      logger.error(`Некорректные данные: ${animeId}, ${episodeNumber}`, 'Home - handleAnimeClickCont');
      return;
    }
    navigate(`/video/${animeId}`, { state: { episode: episodeNumber, studioId } });
  };

  const renderSkeletonBlock = (imageStyle) => {
    return Array(10)
      .fill()
      .map((_, index) => ({
        image: (
          <Skeleton
            key={index}
            width={imageStyle.width}
            height={imageStyle.height}
            baseColor="#222"
            highlightColor="#333"
          />
        ),
        title: <Skeleton width={100} />,
        info: <Skeleton width={80} />,
        description: <Skeleton width={120} />,
      }));
  };

  return (
    <div className="scroll-home">
      <Header />
      <Slider />
      <div className="home-container">
        {showContinueWatching && (
          <AnimeBlock
            title={<Translate textKey="home.continue.watching" />}
            items={
              isContinueWatchingLoading
                ? renderSkeletonBlock({ width: '229px', height: '129px' })
                : continueWatching
            }
            imageStyle={{ width: '229px', height: '129px' }}
            textWidth="229px"
            gap={17}
            overlay={true}
            onCardClick={(item) =>
              handleAnimeClickCont(item.animeId, item.episodeNumber, item.studioId)
            }
          />
        )}

        <AnimeBlock
          title={<Translate textKey="home.high.rated" />}
          items={
            isDataLoading || isPosterLoading
              ? renderSkeletonBlock({ width: '128px', height: '182px' })
              : highRatedFull.map((anime) => ({
                  image: anime.material_data.anime_poster_url,
                  title: anime.title,
                  info: `${anime.material_data.episodes_total} серий`,
                  rating: anime.material_data.shikimori_rating,
                  description: `${anime.year}, ${anime.material_data.anime_genres?.join(', ')}`,
                  id: anime.id,
                }))
          }
          imageStyle={{ width: '128px', height: '182px' }}
          textWidth="128px"
          gap={27}
          hoverEffect={true}
          onCardClick={(item) => handleAnimeClick(item.id)}
        />

        <AnimeBlock
          title={<Translate textKey="home.top.anime.2024" />}
          items={
            isDataLoading || isPosterLoading
              ? renderSkeletonBlock({ width: '128px', height: '182px' })
              : highRated.map((anime) => ({
                  image: anime.material_data.anime_poster_url,
                  title: anime.title,
                  info: `${anime.material_data.episodes_total} серий`,
                  rating: anime.material_data.shikimori_rating,
                  description: `${anime.year}, ${anime.material_data.anime_genres?.join(', ')}`,
                  id: anime.id,
                }))
          }
          imageStyle={{ width: '128px', height: '182px' }}
          textWidth="128px"
          gap={27}
          hoverEffect={true}
          onCardClick={(item) => handleAnimeClick(item.id)}
        />

        <AnimeBlock
          title={<Translate textKey="home.top.anime.2023" />}
          items={
            isDataLoading || isPosterLoading
              ? renderSkeletonBlock({ width: '128px', height: '182px' })
              : topAnime2023.map((anime) => ({
                  image: anime.material_data.anime_poster_url,
                  title: anime.material_data.title, // или anime.title, смотря как хотите
                  info: `${anime.material_data.episodes_total} серий`,
                  rating: anime.material_data.shikimori_rating,
                  description: `${anime.year}, ${anime.material_data.anime_genres?.join(', ')}`,
                  id: anime.id,
                }))
          }
          imageStyle={{ width: '128px', height: '182px' }}
          textWidth="128px"
          gap={27}
          hoverEffect={true}
          onCardClick={(item) => handleAnimeClick(item.id)}
        />
      </div>
    </div>
  );
};

export default Home;
