const translationRu = {
    'global.loading.text': 'Загрузка...',
    'global.error.general': 'Произошла ошибка',
    'global.error.notFound': 'Страница не найдена',
    'global.error.unauthorized': 'Вы не авторизованы',
    'global.buttons.submit': 'Отправить',
    'global.buttons.cancel': 'Отмена',
    'global.buttons.save': 'Сохранить',
    'global.messages.success': 'Операция выполнена успешно',
    'global.messages.welcome': 'Добро пожаловать',

    'global.navigation.home': 'Главная',
    'global.navigation.history': 'История',
    'global.navigation.random': 'Случайное',
    'global.navigation.search': 'Поиск',


    'profile.header.title': 'Профиль',
    'profile.page.language': 'Язык',
    'profile.page.language.ru': 'Русский',
    'profile.page.language.en': 'Английский',
    'profile.page.button.login': 'Войти',
    'profile.page.button.logout': 'Выйти',
    'profile.page.button.profile': 'Профиль',
    'profile.page.watchlist': 'Буду смотреть',
    'profile.page.favorites': 'Избранное',
    'profile.page.dropped': 'Заброшено',
    'profile.page.watched': 'Просмотрено',

    'anime.info.description': 'Описание',
    'anime.info.screenshots': 'Кадры',
    'anime.info.button.add': 'Добавить в список',
    'anime.info.button.addlist': 'Добавить в список',
    'anime.info.button.remove': 'Удалить из списка',
    'anime.info.button.watch': 'Смотреть',

    'anime.info.button.watchlist': 'буду смотреть',
    'anime.info.button.favorites': 'избранное',
    'anime.info.button.dropped': 'заброшено',
    'anime.info.button.watched': 'просмотрено',

    'anime.info.rating': 'Рейтинг: ',
    'anime.info.season': 'Сезон: ',
    'anime.info.episodes': 'Эпизоды: ',
    'anime.info.age': 'Возрастные ограничения: ',
    'anime.info.year': 'Год: ',
    'anime.info.genres': 'Жанры: ',
    'anime.info.voice': 'Озвучка: ',
    'anime.info.voice.notfound': 'Озвучка не найдена',

    'home.continue.watching': 'Продолжить просмотр',
    'home.high.rated': 'С высоким рейтингом',
    'home.top.anime.2024': 'Топ аниме 2024',
    'home.top.anime.2023': 'Топ аниме 2023',

    // 'anime.info.episodes': 'серий',
};

export default translationRu;
