import React from 'react';
import Header from '../components/Header';
import SearchPage from '../components/SearchPage';
import './Search.css';

const Search = () => {

    return (
        <div>
            <Header />
            <SearchPage />
        </div>
    );
};

export default Search;
