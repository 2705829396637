import React from "react";
import Header from "../components/Header";
import HistoryPage from "../components/HistoryPage";
import "./History.css";

const History = ({ user, setUser }) => {
  if (!user) {
    return (
      <div>
        <Header />
        <div className="profile-page">
        </div>
      </div>
    );
  }

  return (
    <div>
      <Header />
      <HistoryPage user={user} setUser={setUser} />
    </div>
  );
};

export default History;
