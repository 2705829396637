import React, { useEffect, useState } from "react";
import "./Header.css";
import { ReactComponent as Logo } from "./logo.svg";
import { useNavigate, Link } from "react-router-dom";
import logger from "./Logger";
import Translate from "./Translate";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Header = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [username, setUsername] = useState(localStorage.getItem("username"));
  const [isAuthLoading, setIsAuthLoading] = useState(true);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");

    if (authToken) {
      if (!username) {
        fetch(`${API_BASE_URL}/users/me`, {
          headers: { Authorization: `Bearer ${authToken}` },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Ошибка при получении данных пользователя");
            }
            return response.json();
          })
          .then((data) => {
            setUser(data);
            setUsername(data.username);
            localStorage.setItem("username", data.username);
            setIsAuthLoading(false);
          })
          .catch((err) => {
            logger.error(`Ошибка авторизации: ${err}`, "Header - useEffect");
            localStorage.removeItem("authToken");
            localStorage.removeItem("username");
            setUser(null);
            setUsername(null);
            setIsAuthLoading(false);
          });
      } else {
        setUser({ username });
        setIsAuthLoading(false);
      }
    } else {
      setIsAuthLoading(false);
    }
  }, [username, API_BASE_URL]);

  const handleRandomClick = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/anime/random`);
      if (!response.ok) {
        throw new Error(`Ошибка HTTP: ${response.status}`);
      }
      const randomAnime = await response.json();
      navigate(`/info/${randomAnime.id}`);
    } catch (error) {
      logger.error(`Ошибка при получении случайного аниме: ${error}`, "Header - handleRandomClick");
    }
  };

  const handleLoginClick = () => {
    if (user) {
      navigate("/profile");
    } else {
      navigate("/login");
    }
  };

  return (
    <header className="header">
      <div className="logo">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <nav className="nav">
        <a href="/">
          <Translate textKey="global.navigation.home" />
        </a>
        <a href="/history">
          <Translate textKey="global.navigation.history" />
        </a>
        <button className="random-button" onClick={handleRandomClick}>
          <Translate textKey="global.navigation.random" />
        </button>
        <a href="/search">
          <Translate textKey="global.navigation.search" />
        </a>
      </nav>

      <button
        className={`login-button ${user ? "user-button" : ""}`}
        onClick={handleLoginClick}
      >
        {isAuthLoading ? (
          <Skeleton width={60} height={20} baseColor="#222" highlightColor="#333" />
        ) : user ? (
          <Translate textKey="profile.page.button.profile" />
        ) : (
          <Translate textKey="profile.page.button.login" />
        )}
      </button>
    </header>
  );
};

export default Header;
