import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useNavigate } from "react-router-dom";
import "./AnimeInfo.css";
import logger from "./Logger";
import Translate from "./Translate";
import { useCallback } from 'react';

const AnimeInfo = ({ animeId }) => {
    const [animeData, setAnimeData] = useState(null);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [isPosterLoading, setIsPosterLoading] = useState(true);
    const [isScreenshotsLoading, setIsScreenshotsLoading] = useState(true);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [voiceLoading, setVoiceLoading] = useState(false);
    const [animeDataVoice, setAnimeDataVoice] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [library, setLibrary] = useState({
        watchlist: [],
        favorites: [],
        watched: [],
        dropped: [],
    });

    const navigate = useNavigate();
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const fetchLibrary = useCallback(async () => {
        const token = localStorage.getItem("authToken");
        if (!token) {
            setIsAuthenticated(false);
            return;
        }

        try {
            const response = await fetch(`${API_BASE_URL}/library`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem("authToken");
                    localStorage.removeItem("username");
                    setIsAuthenticated(false);
                }
                throw new Error("Ошибка при загрузке библиотеки.");
            }

            const data = await response.json();
            setLibrary(data);
            setIsAuthenticated(true);
        } catch (error) {
            logger.error(`Ошибка при загрузке библиотеки: ${error}`, "AnimeInfo - fetchLibrary");
            setIsAuthenticated(false);
        }
    }, [API_BASE_URL]);

    useEffect(() => {
        fetchLibrary();
    }, [API_BASE_URL, fetchLibrary]);


    useEffect(() => {
        logger.log(`Начало загрузки данных для animeId: ${animeId}`, "AnimeInfo - useEffect");
        setIsDataLoading(true);
        setIsScreenshotsLoading(true);
        setIsPosterLoading(true);

        const fetchAnimeData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/anime/${animeId}`);
                if (!response.ok) {
                    throw new Error("Ошибка при загрузке данных аниме.");
                }

                const data = await response.json();
                logger.log(`Данные загружены.`, "AnimeInfo - fetchAnimeData");
                setAnimeData(data);
                setIsDataLoading(false);

                const posterImg = new Image();
                posterImg.src = data?.material_data?.anime_poster_url;
                if (posterImg.complete) {
                    setIsPosterLoading(false);
                } else {
                    posterImg.onload = () => setIsPosterLoading(false);
                    posterImg.onerror = () => setIsPosterLoading(false);
                }

                if (data?.screenshots?.length) {
                    logger.log(`Начало загрузки изображений: ${data.screenshots}`, "AnimeInfo - fetchAnimeData");
                    await Promise.all(
                        data.screenshots.map(
                            (src) =>
                                new Promise((resolve) => {
                                    const img = new Image();
                                    img.src = src;
                                    if (img.complete) {
                                        resolve();
                                    } else {
                                        img.onload = resolve;
                                        img.onerror = resolve;
                                    }
                                })
                        )
                    );
                    logger.log(`Все изображения загружены.`, "AnimeInfo - fetchAnimeData");
                }

                setIsScreenshotsLoading(false);
            } catch (error) {
                logger.error(`Ошибка загрузки данных: ${error}`, "AnimeInfo - fetchAnimeData");
                setIsDataLoading(false);
                setIsPosterLoading(false);
                setIsScreenshotsLoading(false);
            }
        };

        fetchAnimeData();
    }, [animeId, API_BASE_URL]);

    useEffect(() => {
        if (!animeData?.title) return;

        const fetchAnimeDataVoice = async () => {
            setVoiceLoading(true);
            try {
                const encodedTitle = encodeURIComponent(animeData.title);
                const response = await fetch(`${API_BASE_URL}/anime/voice-check/${encodedTitle}`);
                if (!response.ok) {
                    throw new Error("Ошибка при загрузке данных об озвучке.");
                }

                const dataVoice = await response.json();
                setAnimeDataVoice(dataVoice);
            } catch (error) {
                logger.error(`Ошибка при загрузке озвучки: ${error}`, "AnimeInfo - fetchAnimeDataVoice");
            } finally {
                setVoiceLoading(false);
            }
        };

        fetchAnimeDataVoice();
    }, [animeData?.title, API_BASE_URL]);

    const handlePlayClick = () => {
        logger.log(`Переход к плееру.`, "AnimeInfo - handlePlayClick");
        navigate(`/video/${animeId}`);
    };

    const toggleDropdown = () => {
        setDropdownOpen((prevState) => !prevState);
    };

    const handleLibraryToggle = async (listType) => {
        const token = localStorage.getItem("authToken");
        if (!token) return;

        const isInList = library[listType]?.includes(animeId);

        try {
            if (listType === "watched" && !isInList) {
                if (library.watchlist?.includes(animeId)) {
                    await fetch(`${API_BASE_URL}/library`, {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify({ animeId, listType: "watchlist" }),
                    });
                }
                if (library.dropped?.includes(animeId)) {
                    await fetch(`${API_BASE_URL}/library`, {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify({ animeId, listType: "dropped" }),
                    });
                }
            }

            if (isInList) {
                const response = await fetch(`${API_BASE_URL}/library`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ animeId, listType }),
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    logger.error(`Ошибка при удалении: ${errorData}`, "AnimeInfo - handleLibraryToggle");
                    throw new Error(errorData.message || "Ошибка обновления библиотеки.");
                }
            } else {
                const response = await fetch(`${API_BASE_URL}/library`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ animeId, listType }),
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    logger.error(`Ошибка при добавлении: ${errorData}`, "AnimeInfo - handleLibraryToggle");
                    throw new Error(errorData.message || "Ошибка обновления библиотеки.");
                }
            }

            await fetchLibrary();
        } catch (error) {
            logger.error(`Ошибка обновления библиотеки: ${error}`, "AnimeInfo - handleLibraryToggle");
        }
    };

    useEffect(() => {
        if (animeData?.screenshots?.length) {
            animeData.screenshots.forEach((src) => {
                const img = new Image();
                img.src = src;
            });
        }
    }, [animeData]);

    const openLightbox = (index) => {
        setCurrentImageIndex(null);
        setTimeout(() => {
            setCurrentImageIndex(index);
            setLightboxOpen(true);
        }, 10);
    };

    return (
        <div className="anime-info-page">
            <div className="anime-header">
                {isPosterLoading ? (
                    <Skeleton
                        width={250}
                        height={353.33}
                        className="poster-skeleton"
                        baseColor="#222"
                        highlightColor="#333"
                    />
                ) : (
                    <img
                        src={animeData?.material_data?.anime_poster_url}
                        alt={animeData?.title}
                        className="anime-poster"
                    />
                )}
                <div className="anime-details">
                    <h1 className="main-title-anime-info">
                        {isDataLoading ? <Skeleton width={300} /> : animeData?.title}
                    </h1>
                    <p className="other_title">
                        {isDataLoading ? <Skeleton width={200} /> : animeData?.other_title}
                    </p>
                    <ul className="details-list">
                        <li>
                            <strong>
                                <Translate textKey={`anime.info.rating`} />
                            </strong>{" "}
                            {isDataLoading ? (
                                <Skeleton width={50} />
                            ) : (
                                animeData?.material_data?.shikimori_rating
                            )}
                        </li>
                        <li>
                            <strong>
                                <Translate textKey={`anime.info.season`} />
                            </strong>{" "}
                            {isDataLoading ? <Skeleton width={30} /> : animeData?.last_season}
                        </li>
                        <li>
                            <strong>
                                <Translate textKey={`anime.info.episodes`} />
                            </strong>{" "}
                            {isDataLoading ? (
                                <Skeleton width={30} />
                            ) : (
                                animeData?.material_data?.episodes_total
                            )}
                        </li>
                        <li>
                            <strong>
                                <Translate textKey={`anime.info.age`} />
                            </strong>{" "}
                            {isDataLoading ? (
                                <Skeleton width={40} />
                            ) : (
                                animeData?.material_data?.minimal_age
                            )}
                        </li>
                        <li>
                            <strong>
                                <Translate textKey={`anime.info.year`} />
                            </strong>{" "}
                            {isDataLoading ? <Skeleton width={50} /> : animeData?.year}
                        </li>
                        <li>
                            <strong>
                                <Translate textKey={`anime.info.genres`} />
                            </strong>{" "}
                            {isDataLoading ? (
                                <Skeleton width={200} />
                            ) : (
                                animeData?.material_data?.anime_genres?.join(", ")
                            )}
                        </li>
                        {voiceLoading ? (
                            <li>
                                <Skeleton width={150} />
                            </li>
                        ) : animeDataVoice?.studios ? (
                            <li>
                                <strong>
                                    <Translate textKey={`anime.info.voice`} />
                                </strong>{" "}
                                {Object.keys(animeDataVoice.studios)
                                    .filter((studio) => studio !== "Субтитры")
                                    .join(", ")}
                            </li>
                        ) : (
                            <li>
                                <Translate textKey={`anime.info.voice.notfound`} />
                            </li>
                        )}
                    </ul>
                </div>
            </div>

            <div className="anime-buttons">
                <button className="icon-button" onClick={handlePlayClick} disabled={isDataLoading}>
                    <span>
                        {isDataLoading ? (
                            <Skeleton width={50} />
                        ) : (
                            <Translate textKey={`anime.info.button.watch`} />
                        )}
                    </span>
                </button>
                {isAuthenticated && (
                    <button className="icon-button" onClick={toggleDropdown} disabled={isDataLoading}>
                        <span>
                            {isDataLoading ? (
                                <Skeleton width={50} />
                            ) : (
                                <Translate textKey={`anime.info.button.addlist`} />
                            )}
                        </span>
                    </button>
                )}

                {dropdownOpen && isAuthenticated && (
                    <div className="dropdown-menu-info">
                        {["watchlist", "favorites", "watched", "dropped"].map((listType) => {
                            const translatedTypes = {
                                watchlist: <Translate textKey={`anime.info.button.watchlist`} />,
                                favorites: <Translate textKey={`anime.info.button.favorites`} />,
                                watched: <Translate textKey={`anime.info.button.watched`} />,
                                dropped: <Translate textKey={`anime.info.button.dropped`} />,
                            };

                            return (
                                <div key={listType} className="dropdown-item">
                                    <button
                                        className={`dropdown-button ${library[listType]?.includes(animeId) ? "active" : ""
                                            }`}
                                        onClick={() => handleLibraryToggle(listType)}
                                    >
                                        {library[listType]?.includes(animeId) ? (
                                            <>
                                                <Translate textKey={`anime.info.button.remove`} />{" "}
                                                {translatedTypes[listType]}
                                            </>
                                        ) : (
                                            <>
                                                <Translate textKey={`anime.info.button.add`} />{" "}
                                                {translatedTypes[listType]}
                                            </>
                                        )}
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>

            <div className="anime-description">
                <h3>
                    <Translate textKey="anime.info.description" />
                </h3>
                {isDataLoading ? (
                    <Skeleton count={5} />
                ) : (
                    <p>{animeData?.material_data?.description || ""}</p>
                )}
            </div>

            <div className="anime-screenshots">
                <h3>
                    <Translate textKey="anime.info.screenshots" />
                </h3>
                <div className="screenshots-grid">
                    {isScreenshotsLoading
                        ? [...Array(5)].map((_, index) => (
                            <Skeleton key={index} width={227.59} height={128.02} />
                        ))
                        : animeData?.screenshots?.map((screenshot, index) => (
                            <img
                                key={index}
                                src={screenshot}
                                alt={`Кадр ${index + 1}`}
                                className="screenshot"
                                onClick={() => openLightbox(index)}
                            />
                        ))}
                </div>
            </div>

            {lightboxOpen && animeData?.screenshots?.length && (
                <Lightbox
                    mainSrc={animeData.screenshots[currentImageIndex]}
                    nextSrc={
                        animeData.screenshots[
                        (currentImageIndex + 1) % animeData.screenshots.length
                        ]
                    }
                    prevSrc={
                        animeData.screenshots[
                        (currentImageIndex + animeData.screenshots.length - 1) %
                        animeData.screenshots.length
                        ]
                    }
                    enableZoom={false}
                    onAfterOpen={() => logger.log(`Лайтбокс открыт`, "AnimeInfo - openLightbox")}
                    onCloseRequest={() => setLightboxOpen(false)}
                    onMovePrevRequest={() =>
                        setCurrentImageIndex(
                            (currentImageIndex + animeData.screenshots.length - 1) %
                            animeData.screenshots.length
                        )
                    }
                    onMoveNextRequest={() =>
                        setCurrentImageIndex(
                            (currentImageIndex + 1) % animeData.screenshots.length
                        )
                    }
                />
            )}
        </div>
    );
};

export default AnimeInfo;
