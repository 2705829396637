import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './SearchPage.css';
import logger from "./Logger";

const SearchPage = () => {
  logger.log('SearchPage компонент рендерится', 'SearchPage - render');

  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchType, setSearchType] = useState('all');

  const navigate = useNavigate();

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const fetchAnime = useCallback(async (page = 1) => {
    logger.log(`Вызван fetchAnime(page=${page}, searchType=${searchType}, query=${query})`, 'SearchPage - fetchAnime');

    setLoading(true);
    setError(null);

    try {
      let url;

      if (['2024', '2023', '2022'].includes(searchType)) {
        url = `${API_BASE_URL}/anime/released?year=${searchType}&page=${page}`;
      } else {
        if (query.trim() === '') {
          logger.log('Пустой поисковый запрос, останавливаемся', 'SearchPage - fetchAnime');
          setLoading(false);
          setResults([]);
          return;
        }
        url = `${API_BASE_URL}/anime/search?query=${encodeURIComponent(query)}`;
      }

      logger.log(`Выполняется запрос к URL: ${url}`, 'SearchPage - fetchAnime');
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error('Ошибка при выполнении поиска.');
      }

      const data = await response.json();
      logger.log(`Получены данные: ${JSON.stringify(data)}`, 'SearchPage - fetchAnime');

      if (['2024', '2023', '2022'].includes(searchType)) {
        setResults(data.anime || []);
        setTotalPages(data.pagination?.totalPages || 1);
        setCurrentPage(page);

        logger.log(`Результатов: ${data.anime?.length}, страниц: ${data.pagination?.totalPages}`, 'SearchPage - fetchAnime');
      } else if (Array.isArray(data)) {
        setResults(data);
      } else {
        logger.error(`Неверный формат данных: ${JSON.stringify(data)}`, 'SearchPage - fetchAnime');
        setError('Получены некорректные данные от сервера');
        setResults([]);
      }
    } catch (err) {
      logger.error(`Ошибка при выполнении запроса: ${err}`, 'SearchPage - fetchAnime');
      setError(err.message);
      setResults([]);
    } finally {
      setLoading(false);
      logger.log('Загрузка завершена', 'SearchPage - fetchAnime');
    }
  }, [searchType, query, API_BASE_URL]);

  const handleSearch = () => {
    logger.log('Вызван handleSearch', 'SearchPage - handleSearch');

    if (['2024', '2023', '2022'].includes(searchType)) {
      setCurrentPage(1);
    }
    fetchAnime(1);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      logger.log(`Нажата клавиша Enter, запускаем handleSearch()`, 'SearchPage - handleKeyPress');
      handleSearch();
    }
  };

  const handleAnimeClick = (id) => {
    logger.log(`Клик по аниме с ID: ${id}`, 'SearchPage - handleAnimeClick');
    navigate(`/info/${id}`);
  };

  const handlePageChange = (newPage) => {
    logger.log(`Запрошена смена страницы на: ${newPage}`, 'SearchPage - handlePageChange');

    if (!['2024', '2023', '2022'].includes(searchType)) return;

    logger.log('Текущие параметры пагинации', {
      currentPage,
      totalPages,
      isValidPage: newPage >= 1 && newPage <= totalPages
    });

    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      fetchAnime(newPage);
    }
  };

  useEffect(() => {
    logger.log(`searchType изменён на: ${searchType}`, 'SearchPage - useEffect');
    if (['2024', '2023', '2022'].includes(searchType)) {
      fetchAnime(1);
    } else {
      setResults([]);
      setCurrentPage(1);
      setTotalPages(1);
    }
  }, [searchType, fetchAnime]);

  return (
    <div className="search-page">
      <h1>Поиск аниме</h1>

      <div className="search-controls">
        <div className="search-type">
          <button
            className={searchType === 'all' ? 'search-button active' : 'search-button'}
            onClick={() => {
              logger.log('Переключение на общий поиск', 'SearchPage - handleSearchTypeChange');
              setSearchType('all');
            }}
          >
            Общий поиск
          </button>
          <button
            className={searchType === '2024' ? 'search-button active' : 'search-button'}
            onClick={() => {
              logger.log('Переключение на аниме 2024', 'SearchPage - handleSearchTypeChange');
              setSearchType('2024');
            }}
          >
            Аниме 2024
          </button>
          <button
            className={searchType === '2023' ? 'search-button active' : 'search-button'}
            onClick={() => {
              logger.log('Переключение на аниме 2023', 'SearchPage - handleSearchTypeChange');
              setSearchType('2023');
            }}
          >
            Аниме 2023
          </button>
          <button
            className={searchType === '2022' ? 'search-button active' : 'search-button'}
            onClick={() => {
              logger.log('Переключение на аниме 2022', 'SearchPage - handleSearchTypeChange');
              setSearchType('2022');
            }}
          >
            Аниме 2022
          </button>
        </div>

        {searchType === 'all' && (
          <div className="search-bar">
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Введите название аниме..."
            />
            <button onClick={handleSearch}>Поиск</button>
          </div>
        )}
      </div>

      {loading && <div className="loading">Загрузка...</div>}
      {error && <div className="error">{error}</div>}

      <div className="results">
        {results.length > 0 ? (
          <>
            <h2>Результаты поиска</h2>
            <ul>
              {results.map((anime) => (
                <li
                  key={anime.id}
                  className="anime-card-search"
                  onClick={() => handleAnimeClick(anime.id)}
                  style={{ cursor: 'pointer' }}
                >
                  <img
                    src={anime.material_data?.anime_poster_url || '/placeholder.png'}
                    alt={anime.title}
                  />
                  <div className="anime-info">
                    <h3>{anime.title}</h3>
                    <p>{anime.material_data?.description || 'Описание отсутствует.'}</p>
                  </div>
                </li>
              ))}
            </ul>

            {['2024', '2023', '2022'].includes(searchType) && (
              <div className="pagination">
                <button
                  className="search-button"
                  onClick={() => {
                    logger.log('Нажата кнопка "Предыдущая"', 'SearchPage - handlePageChange');
                    handlePageChange(currentPage - 1);
                  }}
                  disabled={currentPage <= 1}
                >
                  Предыдущая
                </button>
                <span>Страница {currentPage} из {totalPages}</span>
                <button
                  className="search-button"
                  onClick={() => {
                    logger.log('Нажата кнопка "Следующая"', 'SearchPage - handlePageChange');
                    handlePageChange(currentPage + 1);
                  }}
                  disabled={currentPage >= totalPages}
                >
                  Следующая
                </button>
              </div>
            )}
          </>
        ) : !loading && (
          <div className="no-results">
            {searchType === 'all' && query
              ? 'В нашей базе данных, такого аниме нет...'
              : 'Введите поисковый запрос'
            }
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchPage;
