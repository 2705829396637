import React, { useRef, useState, useEffect } from 'react';
import './AnimeBlock.css';

const AnimeBlock = ({
    title,
    items,
    imageStyle,
    textWidth,
    gap,
    overlay,
    hoverEffect,
    onCardClick,
    titleStyle = { color: 'white' },
    infoStyle = { color: '#b3b3b3' }
}) => {
    const listRef = useRef(null);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);

    const updateScrollButtons = () => {
        const list = listRef.current;
        if (list) {
            setCanScrollLeft(list.scrollLeft > 0);
            setCanScrollRight(
                Math.ceil(list.scrollLeft + list.clientWidth) < list.scrollWidth
            );
        }
    };

    useEffect(() => {
        const list = listRef.current;
        if (list) {
            updateScrollButtons();
            
            list.addEventListener('scroll', updateScrollButtons);
            
            window.addEventListener('resize', updateScrollButtons);
            
            const resizeObserver = new ResizeObserver(updateScrollButtons);
            resizeObserver.observe(list);

            return () => {
                list.removeEventListener('scroll', updateScrollButtons);
                window.removeEventListener('resize', updateScrollButtons);
                resizeObserver.disconnect();
            };
        }
    }, [items]);

    const scrollLeft = () => {
        const list = listRef.current;
        if (list) {
            const scrollAmount = list.clientWidth;
            list.scrollBy({
                left: -scrollAmount,
                behavior: 'smooth'
            });
        }
    };

    const scrollRight = () => {
        const list = listRef.current;
        if (list) {
            const scrollAmount = list.clientWidth;
            list.scrollBy({
                left: scrollAmount,
                behavior: 'smooth'
            });
        }
    };

    return (
        <div className="anime-block">
            <h3>{title}</h3>
            <div className="anime-block-container">
                {canScrollLeft && (
                    <button className="scroll-button left" onClick={scrollLeft}>
                        ◀
                    </button>
                )}
                <div 
                    className="anime-list" 
                    ref={listRef} 
                    style={{ gap: `${gap}px` }}
                >
                    {items.map((item, index) => (
                        <div
                            key={index}
                            className={`anime-card ${hoverEffect ? 'hover-enabled' : ''}`}
                            onClick={() => onCardClick && onCardClick(item)}
                        >
                            <div className="image-container">
                                {React.isValidElement(item.image) ? (
                                    item.image
                                ) : (
                                    <img src={item.image} alt={item.title} style={imageStyle} />
                                )}
                                {overlay && <div className="overlay"></div>}
                                {hoverEffect && item.description && (
                                    <div className="description">
                                        <p className="description-text">{item.description}</p>
                                    </div>
                                )}
                                {item.rating && <div className="rating-badge">{item.rating}</div>}
                                {item.progress !== undefined && (
                                    <div className="progress-bar">
                                        <div
                                            className="progress-fill"
                                            style={{ width: `${item.progress}%` }}
                                        ></div>
                                    </div>
                                )}
                            </div>
                            <p style={{ ...titleStyle, maxWidth: textWidth }}>{item.title}</p>
                            <span style={{ ...infoStyle, maxWidth: textWidth }}>{item.info}</span>
                        </div>
                    ))}
                </div>
                {canScrollRight && (
                    <button className="scroll-button right" onClick={scrollRight}>
                        ▶
                    </button>
                )}
            </div>
        </div>
    );
};

export default AnimeBlock;
