import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Player.css';
import logger from './Logger';

const Player = ({ animeId }) => {
    const navigate = useNavigate();
    //   const seasonsRef = useRef(null);
    const location = useLocation();

    const [isPlayerDropdownOpen, setPlayerDropdownOpen] = useState(false);
    const [isAudioDropdownOpen, setAudioDropdownOpen] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState('Kodik');
    const [selectedAudio, setSelectedAudio] = useState(null);
    const [animeData, setAnimeData] = useState(null);
    const [, setCurrentSeason] = useState(1);

    //   const [currentSeason, setCurrentSeason] = useState(1);
    const [currentEpisodeLinks, setCurrentEpisodeLinks] = useState([]);
    const [iframeSrc, setIframeSrc] = useState('');
    const [selectedEpisode, setSelectedEpisode] = useState(null);
    const [availableAudios, setAvailableAudios] = useState([]);
    const [animeDataVoice, setAnimeDataVoice] = useState(null);

    const currentDurationRef = useRef(0);
    const lastUpdateTimeRef = useRef(0);
    const isUpdatingRef = useRef(false);

    const initialEpisode = location.state?.episode || null;
    const initialStudioId = location.state?.studioId;

    const players = ['Kodik'];

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const sendHistoryUpdate = useCallback(
        async (animeId, episodeNumber, watchedTime, duration) => {
            if (isUpdatingRef.current) return;
            isUpdatingRef.current = true;

            try {
                const token = localStorage.getItem('authToken');
                const selectedStudio = selectedAudio;
                const studioId = animeDataVoice?.studios?.[selectedStudio]?.[0];

                const response = await fetch(`${API_BASE_URL}/history`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        animeId,
                        episodeNumber,
                        watchedTime,
                        duration,
                        studioId,
                    }),
                });

                if (!response.ok) {
                    throw new Error(`Ошибка при обновлении истории: ${response.status}`);
                }

                logger.log('История успешно обновлена', 'Player - sendHistoryUpdate');
            } catch (error) {
                logger.error(`Ошибка при отправке данных истории: ${error}`, 'Player - sendHistoryUpdate');
            } finally {
                isUpdatingRef.current = false;
            }
        },
        [selectedAudio, animeDataVoice, API_BASE_URL]
    );

    const changeEpisode = useCallback((url, name) => {
        setIframeSrc(url);
        setSelectedEpisode(name);

        const episodeNumber = parseInt(name.replace(/\D/g, ''), 10) || 0;
        sendHistoryUpdate(animeId, episodeNumber, 0);
    }, [animeId, sendHistoryUpdate]);

    const playNextEpisode = useCallback(() => {
        logger.log('Попытка воспроизведения следующей серии', 'Player - playNextEpisode');

        if (!currentEpisodeLinks.length || !selectedEpisode) {
            logger.warn('Нет доступных эпизодов или текущий эпизод не выбран', 'Player - playNextEpisode');
            return;
        }

        const currentIndex = currentEpisodeLinks.findIndex((ep) => ep.name === selectedEpisode);
        logger.log(`Текущий индекс эпизода: ${currentIndex}`, 'Player - playNextEpisode');

        if (currentIndex === -1) {
            logger.error('Текущий эпизод не найден в списке', 'Player - playNextEpisode');
            return;
        }

        if (currentIndex < currentEpisodeLinks.length - 1) {
            const nextEpisode = currentEpisodeLinks[currentIndex + 1];
            logger.log(`Переключение на следующий эпизод: ${nextEpisode.name}`, 'Player - playNextEpisode');
            changeEpisode(nextEpisode.url, nextEpisode.name);
        } else {
            logger.log('Достигнут конец списка эпизодов', 'Player - playNextEpisode');
        }

    }, [currentEpisodeLinks, selectedEpisode, changeEpisode]);

    useEffect(() => {
        const fetchAnimeData = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/anime/${animeId}`);
                if (!response.ok) throw new Error(`Ошибка HTTP: ${response.status}`);
                const data = await response.json();
                setAnimeData(data);

                const voiceResponse = await fetch(
                    `${API_BASE_URL}/anime/voice-check/${encodeURIComponent(data.title)}`
                );
                if (!voiceResponse.ok) throw new Error(`Ошибка HTTP: ${voiceResponse.status}`);
                const voiceData = await voiceResponse.json();
                setAnimeDataVoice(voiceData);

                const filteredAudios = Object.keys(voiceData.studios || {}).filter(
                    (studio) => studio !== 'Субтитры'
                );
                setAvailableAudios(filteredAudios);

                let selectedStudio;
                if (initialStudioId) {
                    selectedStudio = Object.entries(voiceData.studios || {}).find(([_, ids]) =>
                        ids.includes(initialStudioId)
                    );
                }
                if (!selectedStudio) {
                    selectedStudio = Object.entries(voiceData.studios || {}).find(([_, ids]) =>
                        ids.includes(animeId)
                    );
                }
                const audioToSelect = selectedStudio ? selectedStudio[0] : filteredAudios[0] || null;
                setSelectedAudio(audioToSelect);

                const studioId =
                    initialStudioId || (selectedStudio ? voiceData.studios[audioToSelect][0] : null);
                if (studioId) {
                    const animeResponse = await fetch(`${API_BASE_URL}/anime/${studioId}`);
                    if (!animeResponse.ok) throw new Error(`Ошибка HTTP: ${animeResponse.status}`);
                    const animeData = await animeResponse.json();

                    const seasons = animeData.seasons || {};
                    const firstSeasonKey = Object.keys(seasons)[0];
                    if (firstSeasonKey) {
                        const seasonData = seasons[firstSeasonKey];
                        const episodes = Object.entries(seasonData.episodes || {}).map(([key, value]) => ({
                            name: `Серия ${key}`,
                            url: value.link,
                            screenshots: value.screenshots,
                        }));

                        setCurrentSeason(firstSeasonKey);
                        setCurrentEpisodeLinks(episodes);

                        const selectedEp = initialEpisode
                            ? episodes.find(
                                (ep) => parseInt(ep.name.replace(/\D/g, ''), 10) === initialEpisode
                            )
                            : episodes[0];
                        if (selectedEp) {
                            setIframeSrc(selectedEp.url || '');
                            setSelectedEpisode(selectedEp.name || null);
                        }
                    }
                }
            } catch (error) {
                logger.error(`Ошибка при загрузке данных аниме: ${error}`, 'Player - fetchAnimeData');
            }
        };

        fetchAnimeData();
    }, [animeId, initialEpisode, initialStudioId, API_BASE_URL]);

    const handleRedirect = () => {
        navigate(`/info/${animeId}`);
    };

    const selectAudio = async (audio) => {
        logger.log(`Выбрана озвучка: ${audio}`, 'Player - selectAudio');

        try {
            setSelectedAudio(audio);
            setAudioDropdownOpen(false);

            const studioId = animeDataVoice.studios[audio]?.[0];
            if (!studioId) throw new Error('ID студии не найден.');

            const response = await fetch(`${API_BASE_URL}/anime/${studioId}`);
            if (!response.ok) {
                throw new Error(`Ошибка HTTP: ${response.status}`);
            }
            const data = await response.json();

            const seasons = data.seasons || {};
            const seasonKey = Object.keys(seasons)[0];
            const seasonData = seasons[seasonKey];

            if (!seasonData || !seasonData.episodes) {
                throw new Error('Данные о сезонах или эпизодах отсутствуют.');
            }

            const episodes = Object.entries(seasonData.episodes).map(([key, value]) => ({
                name: `Серия ${key}`,
                url: value.link,
                screenshots: value.screenshots,
            }));

            setCurrentSeason(seasonKey);
            setCurrentEpisodeLinks(episodes);

            const matchingEpisode = episodes.find((episode) => episode.name === selectedEpisode);
            if (matchingEpisode) {
                logger.log(`Совпадающий эпизод найден: ${matchingEpisode.name}`, 'Player - selectAudio');
                setIframeSrc(matchingEpisode.url);
                setSelectedEpisode(matchingEpisode.name);
            } else {
                logger.log(`Совпадающий эпизод не найден. Устанавливаем первый эпизод.`, 'Player - selectAudio');
                setIframeSrc(episodes[0]?.url || '');
                setSelectedEpisode(episodes[0]?.name || null);
            }
        } catch (error) {
            logger.error(`Ошибка при смене озвучки: ${error}`, 'Player - selectAudio');
            setCurrentEpisodeLinks([]);
            setIframeSrc('');
            setSelectedEpisode(null);
        }
    };

    useEffect(() => {
        const handlePlayerEvents = (message) => {
            const { key, value } = message.data;

            if (key === 'kodik_player_duration_update') {
                const duration = Math.round(value);
                logger.log(`Длительность видео: ${duration} секунд`, 'Player - handlePlayerEvents');
                currentDurationRef.current = duration;
            }

            if (key === 'kodik_player_time_update') {
                const duration = currentDurationRef.current;
                if (!duration || duration <= 0) {
                    logger.warn('Длительность видео еще не обновлена. Пропускаем отправку.', 'Player - handlePlayerEvents');
                    return;
                }

                const currentTime = Date.now();
                if (currentTime - lastUpdateTimeRef.current >= 3000) {
                    const episodeNumber = parseInt(selectedEpisode.replace(/\D/g, ''), 10);
                    const watchedTime = value;

                    sendHistoryUpdate(animeId, episodeNumber, watchedTime, duration);

                    lastUpdateTimeRef.current = currentTime;
                    logger.log(
                        `Отправка данных в историю: ${animeId} ${episodeNumber} ${watchedTime} ${duration}`,
                        'Player - handlePlayerEvents'
                    );

                    if (Math.abs(value - duration) < 30) {
                        logger.log('Серия завершается, автопереход на следующую...', 'Player - handlePlayerEvents');
                        setTimeout(() => {
                            playNextEpisode();
                        }, 1000);
                    }
                }
            }
        };

        window.addEventListener('message', handlePlayerEvents);
        return () => {
            window.removeEventListener('message', handlePlayerEvents);
        };
    }, [animeId, selectedEpisode, sendHistoryUpdate, playNextEpisode]);

    useEffect(() => {
        if (!animeData?.title) return;

        const fetchAnimeDataVoice = async () => {
            try {
                const response = await fetch(
                    `${API_BASE_URL}/anime/voice-check/${encodeURIComponent(animeData.title)}`
                );
                if (!response.ok) throw new Error('Ошибка при загрузке данных об озвучке.');
                const voiceData = await response.json();
                setAnimeDataVoice(voiceData);
            } catch (error) {
                logger.error(`Ошибка при загрузке озвучек: ${error}`, 'Player - fetchAnimeDataVoice');
            }
        };

        fetchAnimeDataVoice();
    }, [animeData?.title, API_BASE_URL]);

    /**
     * Прокрутка сезонов (в будущем будет реализация нескольких сезонов)
     */
    //   const scrollLeft = () => {
    //     seasonsRef.current.scrollBy({
    //       left: -300,
    //       behavior: 'smooth',
    //     });
    //   };

    //   const scrollRight = () => {
    //     seasonsRef.current.scrollBy({
    //       left: 300,
    //       behavior: 'smooth',
    //     });
    //   };

    const togglePlayerDropdown = (e) => {
        e.stopPropagation();
        setPlayerDropdownOpen((prev) => !prev);
        setAudioDropdownOpen(false);
    };

    const toggleAudioDropdown = (e) => {
        e.stopPropagation();
        setAudioDropdownOpen((prev) => !prev);
        setPlayerDropdownOpen(false);
    };

    const selectPlayer = (player) => {
        setSelectedPlayer(player);
        setPlayerDropdownOpen(false);
    };

    if (!animeData || !animeDataVoice || !currentEpisodeLinks.length) {
        return <div>Загрузка...</div>;
    }

    return (
        <div className="player-page">
            <div className="video-section">
                <iframe
                    className="videoclass"
                    src={`${iframeSrc}?hide_selectors=true`}
                    width="610"
                    height="370"
                    frameBorder="0"
                    allowFullScreen
                    allow="autoplay *; fullscreen *"
                    title={`${animeData.title} - ${selectedEpisode || 'Серия 1'}`}
                ></iframe>
                <div className="video-title-info">
                    <div className="video-titles">
                        <h2 className="video-title">{animeData.title}</h2>
                        <p className="video-title-other">{animeData.other_title}</p>
                    </div>
                    <button className="option-button-new active" onClick={handleRedirect}>
                        Открыть информацию
                    </button>
                </div>
            </div>

            <div className="side-panel">
                {/* TODO: Добавить прокрутку сезонов */}
                {/* 
                    <div className="seasons-container">
                    <button className="scroll-button-season left" onClick={scrollLeft}>
                        ◀
                    </button>
                    <div className="seasons" ref={seasonsRef}>
                        {Array.from({ length: animeData.season }, (_, i) => (
                        <button
                            key={i + 1}
                            className={`season-button ${currentSeason === i + 1 ? 'active' : ''}`}
                            onClick={() => setCurrentSeason(i + 1)}
                        >
                            {i + 1} сезон
                        </button>
                        ))}
                    </div>
                    <button className="scroll-button-season right" onClick={scrollRight}>
                        ▶
                    </button>
                    </div> 
                */}

                <div className="episodes">
                    <h3>Список серий</h3>
                    <div className="episode-list">
                        {currentEpisodeLinks.length > 0 ? (
                            currentEpisodeLinks.map((episode, index) => (
                                <div
                                    key={index}
                                    className={`episode-item ${selectedEpisode === episode.name ? 'episode-item-active' : ''
                                        }`}
                                    onClick={() => changeEpisode(episode.url, episode.name)}
                                >
                                    <div
                                        className="episode-thumbnail"
                                        style={{
                                            backgroundImage: `url('${episode.screenshots?.[0] || ''}')`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                        }}
                                    />
                                    <div className="episode-info">
                                        <p>{episode.name}</p>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>Эпизоды отсутствуют</p>
                        )}
                    </div>
                </div>

                <div className="side-panel-bottom">
                    <div className="dropdown">
                        <div className="option-group">
                            <div className="option-text">
                                <h4>Плеер</h4>
                                <p>Выберите плеер.</p>
                            </div>
                            <button className="option-button active" onClick={togglePlayerDropdown}>
                                {selectedPlayer}
                            </button>
                        </div>
                        {isPlayerDropdownOpen && (
                            <ul className="dropdown-menu player">
                                {players.map((player) => (
                                    <li
                                        key={player}
                                        className={player === selectedPlayer ? 'active' : ''}
                                        onClick={() => selectPlayer(player)}
                                    >
                                        {player}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <div className="dropdown">
                        <div className="option-group">
                            <div className="option-text">
                                <h4>Озвучка</h4>
                                <p>Выберите озвучку.</p>
                            </div>
                            <button className="option-button active" onClick={toggleAudioDropdown}>
                                {selectedAudio}
                            </button>
                        </div>
                        {isAudioDropdownOpen && (
                            <ul className="dropdown-menu audio">
                                {availableAudios.map((audio) => (
                                    <li
                                        key={audio}
                                        className={audio === selectedAudio ? 'active' : ''}
                                        onClick={() => selectAudio(audio)}
                                    >
                                        {audio}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Player;
