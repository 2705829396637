import React, { useState } from 'react';
import logger from '../components/Logger';
import './Login.css';

const Register = () => {
  const [formData, setFormData] = useState({ username: '', email: '', password: '' });

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${API_BASE_URL}/auth/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Произошла ошибка при регистрации.');
      }

      const data = await response.json();
      alert(data.message);
    } catch (error) {
      logger.error(`Ошибка регистрации: ${error}`, 'Register - handleSubmit');
      alert(error.message);
    }
  };

  return (
    <div className="login-page-container">
      <div className="login-form-wrapper">
        <h1 className="login-title">Регистрация</h1>
        <form onSubmit={handleSubmit} className="login-form">
          <div className="login-input-group">
            <input
              type="text"
              placeholder="Имя пользователя"
              value={formData.username}
              onChange={(e) => setFormData({ ...formData, username: e.target.value })}
              className="login-input"
            />
          </div>
          <div className="login-input-group">
            <input
              type="email"
              placeholder="Email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              className="login-input"
            />
          </div>
          <div className="login-input-group">
            <input
              type="password"
              placeholder="Пароль"
              value={formData.password}
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
              className="login-input"
            />
          </div>
          <button type="submit" className="login-submit-btn">
            Зарегистрироваться
          </button>
        </form>
        <div className="login-links">
          <a href="/login" className="login-register-link">
            Уже есть аккаунт? Войти
          </a>
        </div>
      </div>
    </div>
  );
};

export default Register;
