import React from 'react';
import translationRu from './translations/t-ru';
import translationEn from './translations/t-en';

const Translate = ({ textKey }) => {
    const getTranslation = () => {
        const currentLanguage = localStorage.getItem('language') || 'ru';
        
        const translations = {
            'ru': translationRu,
            'en': translationEn
        };

        const selectedTranslation = translations[currentLanguage];

        if (!selectedTranslation || !selectedTranslation[textKey]) {
            console.warn(`Translation not found for key: ${textKey}`);
            return textKey;
        }

        return selectedTranslation[textKey];
    };

    return <>{getTranslation()}</>;
};

export default Translate;
