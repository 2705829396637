import React from "react";
import Header from "../components/Header";
import ProfilePage from "../components/ProfilePage";
import "./Profile.css";

const Profile = ({ user, setUser }) => {
  if (!user) {
    return (
      <div>
        <Header />
        <div className="profile-page">
          <h2>Вы не авторизованы</h2>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Header />
      <ProfilePage user={user} setUser={setUser} />
    </div>
  );
};

export default Profile;
